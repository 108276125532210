import cx from 'classnames'

type TProps = {
  context?: 'white' | 'purple'
  checked?: boolean
  className?: string
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const Toggle = ({ className, context = 'white', checked, ...rest }: TProps) => {
  return (
    <div
      className={cx(
        'group rounded-full w-[80px] border-4 p-[2px] transition-all flex relative',
        'pl-[2px] cursor-pointer',
        context === 'white'
          ? checked
            ? 'pl-[44px] bg-purple-60 border-purple-60'
            : 'bg-grey-35 border-grey-35'
          : 'border-purple-0',
        className
      )}
      {...rest}
    >
      <div
        className={cx(
          'rounded-full w-md h-md transition active:pl-md bg-white',
          context === 'white' ? 'bg-grey-20' : 'bg-purple-0',
          checked ? 'bg-purple-60' : ''
        )}
      />
      <span
        className={cx(
          'text-h-xs absolute top-[5px] font-bold',
          checked ? 'left-2xs text-white' : 'left-[36px]'
        )}
      >
        {checked ? 'ON' : 'OFF'}
      </span>
    </div>
  )
}
