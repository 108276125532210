import cx from 'classnames'
import type { TGameListBackground, TGameListDescription, TGameListType } from '../types'
import { LIST_STYLE_COMPONENT_MAPPING } from '../ListStyleVariants'
import { GameListBackground } from '../GameListBackground'
import { GameListTitleSkeleton } from '../GameListTitle/GameListTitleSkeleton'
import { GameListDescription } from '../GameListDescription'
import { ReactNode } from 'react'

type TGameListSkeleton = {
  variant: TGameListType
  title?: ReactNode
} & Partial<TGameListDescription> &
  Partial<TGameListBackground>

export const GameListSkeleton = ({
  variant,
  title,
  backgroundColor,
  backgroundPattern,
  backgroundWithoutPadding,
  copyHeading,
  copySubHeading,
  copySubHeadingColor,
  copyText,
  copyLinkText,
  copyLinkUrl
}: TGameListSkeleton) => {
  const ListStyleComponent = LIST_STYLE_COMPONENT_MAPPING[variant]
  const skeletonClassName = cx(
    backgroundColor === 'white' && 'skeleton-grey',
    backgroundColor === 'grey-0' && 'skeleton-grey',
    backgroundColor === 'purple-80' && 'skeleton-purple',
    backgroundColor === 'purple-gradient' && 'skeleton-purple',
    !backgroundColor && 'skeleton'
  )
  const showDefaultTitle = typeof title !== 'function'

  return (
    <GameListBackground
      backgroundColor={backgroundColor}
      backgroundPattern={backgroundPattern}
      backgroundWithoutPadding={backgroundWithoutPadding}
      className="w-full overflow-hidden"
    >
      {copyHeading && (
        <GameListDescription
          backgroundColor={backgroundColor}
          copyHeading={copyHeading}
          copySubHeading={copySubHeading}
          copySubHeadingColor={copySubHeadingColor}
          copyText={copyText}
          copyLinkText={copyLinkText}
          copyLinkUrl={copyLinkUrl}
        />
      )}
      {showDefaultTitle && <GameListTitleSkeleton skeletonClassName={skeletonClassName} />}
      <ListStyleComponent>
        {[...Array(15).keys()].map((index) => (
          <div key={index} className={cx('rounded-3xl', skeletonClassName)}></div>
        ))}
      </ListStyleComponent>
    </GameListBackground>
  )
}
