import '@casumo/fabric-fundamentals'
import { createRoot, Root } from 'react-dom/client'
import { Application, mapAttributesToParams, TApplicationParams } from 'Components/Applications'
import { APPLICATION_NAME } from './constants'
import './index.css'
import {
  createApplicationContainer,
  createStylesLink,
  createWebComponent,
  type TRootNode
} from './packager/index'
import { EventDispatcherProvider } from 'Components/EventDispatcher'

const renderApplication = async (
  rootNode: TRootNode<Root>,
  node: HTMLElement,
  params: TApplicationParams,
  onReady: () => void
) => {
  const root = createRoot(node)

  root.render(
    <EventDispatcherProvider rootNode={rootNode.host}>
      <Application {...params} />
    </EventDispatcherProvider>
  )
  rootNode.applicationNode = root

  if (window.requestIdleCallback) {
    window.requestIdleCallback(onReady)
  } else {
    // Safari doesn't support requestIdleCallback
    // https://caniuse.com/requestidlecallback
    window.setTimeout(onReady, 1)
  }
}

createWebComponent<Root>(
  APPLICATION_NAME,
  (root, attributes, host, onReady) => {
    Promise.all([
      createApplicationContainer(root),
      createStylesLink(root, `${host}/index.css`)
    ]).then(([container]) =>
      renderApplication(root, container, mapAttributesToParams(attributes), onReady)
    )
  },
  (node) => {
    node?.applicationNode?.unmount()
  }
)
