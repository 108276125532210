import cx from 'classnames'
import { Link } from 'Components/Buttons'
import { type TGameListDescription, type TGameListBackgroundColor } from 'Components/GameList/types'

type TGameListDescriptionParams = TGameListDescription & {
  backgroundColor?: TGameListBackgroundColor
}

export const GameListDescription = ({
  copyHeading,
  copySubHeading,
  copySubHeadingColor = 'salmon-30',
  copyText,
  copyLinkText,
  copyLinkUrl,
  backgroundColor = 'white'
}: TGameListDescriptionParams) => {
  return (
    <div className="px-sm 744:px-2xl pb-lg 1024:pb-2xl">
      {copySubHeading && (
        <h3
          className={cx(
            'text-h-md 744:text-h-lg',
            'break-words',
            'mb-2xs 744:mb-sm 1440:mb-md',
            copySubHeadingColor === 'salmon-30' && 'text-salmon-30',
            copySubHeadingColor === 'teal-60' && 'text-teal-60'
          )}
        >
          {copySubHeading}
        </h3>
      )}
      <div className="flex flex-col 1440:flex-row gap-sm 1440:gap-lg">
        {copyHeading && (
          <h2
            className={cx(
              'text-h-xl 744:text-h-2xl',
              'flex-1 break-words',
              backgroundColor === 'purple-80' && 'text-white',
              backgroundColor === 'purple-gradient' && 'text-white',
              backgroundColor === 'grey-0' && 'text-purple-60',
              backgroundColor === 'white' && 'text-purple-60'
            )}
          >
            {copyHeading}
          </h2>
        )}
        <div className="flex-1">
          {copyText && (
            <div
              className={cx(
                'text-base 744:text-lg break-words',
                backgroundColor === 'purple-80' && 'text-purple-0',
                backgroundColor === 'purple-gradient' && 'text-purple-0',
                backgroundColor === 'grey-0' && 'text-grey-90',
                backgroundColor === 'white' && 'text-grey-90'
              )}
            >
              {copyText}
            </div>
          )}
          {copyLinkText && copyLinkUrl && (
            <Link
              link={copyLinkUrl}
              className={cx(
                'click-transition inline-block underline font-bold text-base 744:text-lg break-words',
                'mt-2xs 744:mt-sm 1440:mt-md',
                backgroundColor === 'purple-80' && 'text-purple-0',
                backgroundColor === 'purple-gradient' && 'text-purple-0',
                backgroundColor === 'grey-0' && 'text-grey-50',
                backgroundColor === 'white' && 'text-grey-50'
              )}
            >
              {copyLinkText}
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default GameListDescription
