import { SearchTagsListSkeleton } from 'Components/Tags/SearchTagsListSkeleton'
import { SearchResultsListTitleSkeleton } from '../GameSearchResults/SearchResultsListTitleSkeleton'
import { GameSearchResultsGridSkeleton } from '../GameSearchResults/GameSearchResultsGridSkeleton'
import { SearchAndFilterFieldSkeleton } from '../SearchField/SearchAndFilterFieldSkeleton'

export function SearchGamesPageSkeleton() {
  return (
    <div className="bg-grey-0 pb-lg">
      <div className="p-sm 744:px-2xl 744:pt-2xl">
        <div className="flex gap-2xs 744:gap-sm 1024:w-1/2">
          <SearchAndFilterFieldSkeleton />
        </div>
      </div>
      <div className="flex flex-col gap-sm 744:gap-lg">
        <SearchTagsListSkeleton title={<SearchResultsListTitleSkeleton />} />
        <GameSearchResultsGridSkeleton />
        <GameSearchResultsGridSkeleton />
      </div>
    </div>
  )
}
