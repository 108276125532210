import type { Fetch } from './types'

export const withHeaders = (
  headers: Record<string, string>,
  fetch: Fetch,
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<Response> => {
  if (init) {
    init.headers = { ...init.headers, ...headers }
    return fetch(input, init)
  } else if (typeof input == 'string' || input instanceof URL) {
    return fetch(input, { headers: { ...headers } })
  } else if (input instanceof Request) {
    for (const [key, value] of Object.entries(headers)) {
      input.headers.append(key, value)
    }
    return fetch(input)
  } else {
    throw Error(`Don't know how to handle input: ${input}, init: ${init}`)
  }
}
