import cx from 'classnames'
import { type TStyleVariantProps } from './types'
import { HorizontalStyleVariant } from './HorizontalStyleVariant'

// 156px / 375px = 41.60%
// 156px / 768px = 20.3125%
// 173px / 1024px = 16.89453125%
// 211px / 1440px = 14.652777778%
// 214px / 1920px = 11.145833333%

export const DefaultStyleVariant = ({ className, children }: TStyleVariantProps) => (
  <HorizontalStyleVariant
    className={cx(
      '*:aspect-[1/1.25]',
      '*:w-[41.60%] 744:*:w-[20.31%] 1024:*:w-[16.89%] 1440:*:w-[14.65%] 1920:*:w-[11.15%]',
      className
    )}
  >
    {children}
  </HorizontalStyleVariant>
)
