import cx from 'classnames'
import { HTMLAttributes, forwardRef } from 'react'
import { type TStyleVariantProps } from './types'
import { VerticalStyleVariant } from './VerticalStyleVariant'

export const CompactGridStyleVariant = forwardRef<
  HTMLDivElement,
  TStyleVariantProps & HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <VerticalStyleVariant
    ref={ref}
    {...props}
    className={cx(
      'grid-cols-2 744:grid-cols-4 1024:grid-cols-5 1440:grid-cols-6 1920:grid-cols-8',
      '*:aspect-[1/1]',
      className
    )}
  >
    {children}
  </VerticalStyleVariant>
))
