import { SkeletonList } from 'Components/GameList/SkeletonList'
import type { TApplicationParams } from './Application'
import { LikesCounterSkeleton } from 'Components/LikesCounter/LikesCounterSkeleton'
import { GameLauncherSkeleton } from 'Components/GameLaunch/GameLauncherSkeleton'
import { SearchGamesPageSkeleton } from 'Components/SearchGames/SearchGamesPage/SearchGamesPageSkeleton'

export const AppLoading = (props: TApplicationParams) => {
  if (props.component === 'list') {
    return <SkeletonList {...props} />
  } else if (props.component === 'studios') {
    return <SkeletonList {...props} />
  } else if (props.component === 'launcher') {
    return <GameLauncherSkeleton />
  } else if (props.component === 'likes-counter') {
    return <LikesCounterSkeleton />
  } else if (props.component === 'studios-page') {
    return <SkeletonList variant="small-compact-grid" title={<></>} />
  } else if (props.component === 'search-results') {
    return <SearchGamesPageSkeleton />
  }

  return null
}
