import cx from 'classnames'
import { HTMLAttributes, forwardRef } from 'react'
import { type TStyleVariantProps } from './types'
import { VerticalStyleVariant } from './VerticalStyleVariant'

export const SmallDefaultGridStyleVariant = forwardRef<
  HTMLDivElement,
  TStyleVariantProps & HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <VerticalStyleVariant
    ref={ref}
    {...props}
    className={cx(
      'grid-cols-3 744:grid-cols-6 1024:grid-cols-7 1440:grid-cols-8 1920:grid-cols-10',
      '*:aspect-[1/1.25]',
      className
    )}
  >
    {children}
  </VerticalStyleVariant>
))
