import cx from 'classnames'
import { type TStyleVariantProps } from './types'
import { HorizontalStyleVariant } from './HorizontalStyleVariant'

// 156px / 375px = 41.6%
// 178px / 768px = 23.254%
// 173px / 1024px = 16.89453125%
// 210px / 1440px = 14.583333333%
// 213px / 1920px = 11.09375%

export const CompactStyleVariant = ({ className, children }: TStyleVariantProps) => (
  <HorizontalStyleVariant
    className={cx(
      '*:aspect-[1/1]',
      '*:w-[41.6%] @744/list:*:w-[23.254%] @1024/list:*:w-[16.89%] @1440/list:*:w-[14.58%] @1920/list:*:w-[11.09%]',
      className
    )}
  >
    {children}
  </HorizontalStyleVariant>
)
