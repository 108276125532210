import cx from 'classnames'
import { type TStyleVariantProps } from './types'
import { HorizontalStyleVariant } from './HorizontalStyleVariant'

// 255px / 375px = 68%
// 213px / 768px = 28.63%
// 220px / 1024px = 21.484375%
// 256px / 1440px = 17.777777778%
// 291px / 1920px = 15.15625%

export const LargeStyleVariant = ({ className, children }: TStyleVariantProps) => (
  <HorizontalStyleVariant
    className={cx(
      '*:aspect-[1/1.186]',
      '*:w-[68%] 744:*:w-[28.63%] 1024:*:w-[21.48%] 1440:*:w-[17.78%] 1920:*:w-[15.16%]',
      className
    )}
  >
    {children}
  </HorizontalStyleVariant>
)
