import { useCallback } from 'react'
import { useEventDispatcher } from './EventDispatcherProvider'

export function useMicroFrontendReadyEvent() {
  const { dispatchEvent } = useEventDispatcher()
  const dispatchReadyEvent = useCallback(() => {
    dispatchEvent(new Event('micro-frontend-ready'))
  }, [dispatchEvent])

  return {
    dispatchMicroFrontendReadyEvent: dispatchReadyEvent
  }
}
