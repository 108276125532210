// https://istio.io/latest/about/faq/distributed-tracing/#how-to-support-tracing
// https://github.com/open-telemetry/opentelemetry-specification/blob/main/specification/trace/api.md#spancontext
// https://opentelemetry.io/docs/concepts/signals/traces/#context-propagation
// https://www.w3.org/TR/trace-context/#traceparent-header
// https://www.w3.org/TR/trace-context/#tracestate-header

import { withHeaders } from './headers'
import { Fetch } from './types'

const TRACE_PARENT = 'traceparent'
const TRACE_STATE = 'tracestate'
const TRACE_ORIGIN = 'origin'

/** Creates a decorated version of the passed in fetch function that attaches a W3C trace context to the requests https://www.w3.org/TR/trace-context/#abstract */
export const createTracedFetch = (fetch: Fetch, traceOrigin = 'no-origin') => {
  return (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
    const version = '00' // as hex
    const traceId = randomHex(16)
    const parentId = randomHex(8)
    const flags = '00' // unsampled - as hex
    const traceparent = `${version}-${traceId}-${parentId}-${flags}`

    const traceState = new TraceState()
    traceState.set(TRACE_ORIGIN, traceOrigin)

    return withHeaders(
      { [TRACE_PARENT]: traceparent, [TRACE_STATE]: traceState.serialize() },
      fetch,
      input,
      init
    )
  }
}

class TraceState<K = string, V = string> extends Map<K, V> {
  serialize = () => {
    const buffer: string[] = []
    this.forEach((value, key) => buffer.push(`${key}=${value}`))
    return buffer.join(';')
  }
}

const randomHex = (length: number) => {
  const toHex = (buffer: ArrayLike<number>) =>
    Array.from(buffer)
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('')

  return toHex(crypto.getRandomValues(new Uint8Array(length)))
}
