import cx from 'classnames'

type TLegacyButton = {
  primary?: boolean
  className?: string
  children: React.ReactNode
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const LegacyButton = ({ className, primary = false, children, ...rest }: TLegacyButton) => {
  return (
    <button
      type="button"
      className={cx(
        'click-transition font-bold border border-white rounded-full h-2xl',
        primary ? 'bg-white text-purple-60' : 'bg-purple-60 text-white',
        className
      )}
      {...rest}
    >
      {children}
    </button>
  )
}
