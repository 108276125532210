import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { AppLoading } from './AppLoading'
import type { TGameListApplication } from './GameListApplication'
import type { TGameStudiosApplication } from './GameStudiosApplication'
import { APPLICATION_COMPONENT_MAPPING } from './applicationComponentMapping'
import type {
  TGameLaunchApplication,
  TLikesCounterApplication,
  TInGameJackpotBarApplication
} from './types'

export type TApplicationParams =
  | { component: 'search-results' | 'info' | 'studios-page' | 'search' | 'casino-games' }
  | ({ component: 'list' } & TGameListApplication)
  | ({ component: 'studios' } & TGameStudiosApplication)
  | ({ component: 'launcher' } & TGameLaunchApplication)
  | ({ component: 'likes-counter' } & TLikesCounterApplication)
  | ({ component: 'in-game-jackpot-bar' } & TInGameJackpotBarApplication)

export const Application = (params: TApplicationParams) => {
  const ApplicationComponent = APPLICATION_COMPONENT_MAPPING[params.component]

  if (!ApplicationComponent) {
    throw new Error('Missing component type')
  }

  return (
    <Suspense fallback={<AppLoading {...params} />}>
      <ErrorBoundary fallback={<div></div>}>
        <ApplicationComponent {...params} />
      </ErrorBoundary>
    </Suspense>
  )
}
