import { LIST_STYLE_COMPONENT_MAPPING } from '../ListStyleVariants'
import { TGameListType } from '../types'

type TLegacyGameListSkeleton = {
  variant: TGameListType
}

export const LegacyGameListSkeleton = ({ variant }: TLegacyGameListSkeleton) => {
  const ListStyleComponent = LIST_STYLE_COMPONENT_MAPPING[variant]

  return (
    <div className="max-w-[1080px] mx-auto mt-md legacy-tablet:mt-lg legacy-desktop:mt-lg">
      <div className="flex justify-between px-sm legacy-tablet:px-2xl legacy-desktop:px-0">
        <h3 className="mb-sm mt-2xs legacy-tablet:mb-md legacy-desktop:mb-md animate-pulse rounded bg-grey-35/20 h-sm w-[128px]"></h3>
      </div>
      <ListStyleComponent className="animate-pulse overflow-hidden">
        {[...Array(15).keys()].map((index) => (
          <div key={index} className="bg-grey-35/20 rounded-3xl"></div>
        ))}
      </ListStyleComponent>
    </div>
  )
}
