import { SkeletonList } from 'Components/GameList/SkeletonList/SkeletonList'
import { GAME_SEARCH_BACKGROUND_COLOR, GAME_SEARCH_LIST_VARIANT } from '../constants'
import { SearchResultsListTitleSkeleton } from './SearchResultsListTitleSkeleton'

export function GameSearchResultsGridSkeleton() {
  return (
    <SkeletonList
      variant={GAME_SEARCH_LIST_VARIANT}
      backgroundColor={GAME_SEARCH_BACKGROUND_COLOR}
      backgroundWithoutPadding={true}
      title={<SearchResultsListTitleSkeleton />}
    />
  )
}
