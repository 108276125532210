type TFormatCurrency = {
  iso4217CurrencyCode: string
  amount: number
  localeCode: string
}

const DEFAULT_NUMBER_FORMAT_OPTIONS: Intl.NumberFormatOptions = {
  style: 'currency',
  currencyDisplay: 'narrowSymbol'
}

// Matches the number up to the n-th decimal place and turn it back into a number
// Supports negative numbers as well
export const truncateToDecimalPlaces = (value: number, dp = 0) => {
  if (typeof value !== 'number') return value

  const operator = value >= 0 ? 1 : -1
  const positiveValue = operator * value

  const regex = new RegExp(`^\\d+(?:\\.\\d{0,${dp}})?`)
  return operator * Number(positiveValue.toString().match(regex))
}

// For Norway we want to display "123 kr" instead of "kr 123"
// And that format is conveniently the same as Sweden so... Thanks Sweden!
const getNorwegianFormatterOverride = () => {
  return new Intl.NumberFormat('sv-SE', {
    ...DEFAULT_NUMBER_FORMAT_OPTIONS,
    currency: 'SEK'
  })
}

const isNorwegianCurrency = (localeCode: string, iso4217CurrencyCode: string) =>
  localeCode === 'no-NO' && iso4217CurrencyCode === 'NOK'

const getIntlFormatter = ({ localeCode, iso4217CurrencyCode }: Omit<TFormatCurrency, 'amount'>) => {
  if (isNorwegianCurrency(localeCode, iso4217CurrencyCode)) {
    return getNorwegianFormatterOverride()
  }

  return new Intl.NumberFormat(localeCode, {
    ...DEFAULT_NUMBER_FORMAT_OPTIONS,
    currency: iso4217CurrencyCode
  })
}

export const formatCurrency = ({ iso4217CurrencyCode, amount, localeCode }: TFormatCurrency) => {
  const formatter = getIntlFormatter({ iso4217CurrencyCode, localeCode })

  // Needed to keep trailing currency symbols and markets that don't use standard decimal places
  const findTrailingZerosRegex = /\D00(?=\D*$)/

  return formatter.format(truncateToDecimalPlaces(amount, 2)).replace(findTrailingZerosRegex, '')
}
