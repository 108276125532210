import { type TApplicationParams } from './Application'
import {
  type TGameTileType,
  type TGameListType,
  type TGameListBackgroundColor,
  type TGameListHeadingColor
} from 'Components/GameList/types'

export const mapAttributesToParams = (attributes: Record<string, string>): TApplicationParams => {
  switch (attributes['component']) {
    case 'list':
      return {
        component: 'list',
        listId: attributes['list-id'],
        title: attributes['list-title'],
        titleLink: attributes['list-title-link'],
        titleIcon: attributes['list-title-icon'],
        variant: attributes['list-type'] as TGameListType,
        backgroundColor: attributes['background-color'] as TGameListBackgroundColor,
        backgroundPattern: attributes['background-pattern'],
        gameTileType: attributes['game-tile-type'] as TGameTileType,
        widgetId: attributes['widget-id'],
        copyHeading: attributes['copy-heading'],
        copySubHeading: attributes['copy-sub-heading'],
        copySubHeadingColor: attributes['copy-sub-heading-color'] as TGameListHeadingColor,
        copyText: attributes['copy-text'],
        copyLinkText: attributes['copy-link-text'],
        copyLinkUrl: attributes['copy-link-url'],
        heroHeading: attributes['hero-heading'],
        heroSubHeading: attributes['hero-sub-heading'],
        heroLinkText: attributes['hero-link-text'],
        heroLinkUrl: attributes['hero-link-url'],
        significantTerms: attributes['sig-terms']
      }
    case 'studios':
      return {
        component: 'studios',
        title: attributes['list-title'],
        titleIcon: attributes['list-title-icon'],
        backgroundColor: attributes['background-color'] as TGameListBackgroundColor,
        variant: attributes['list-type'] as TGameListType
      }
    case 'launcher':
      return {
        component: 'launcher',
        slug: attributes['game-slug'],
        playForFun: attributes['play-for-fun']?.toLowerCase() === 'true'
      }
    case 'likes-counter':
      return {
        component: 'likes-counter',
        slug: attributes['game-slug']
      }
    case 'in-game-jackpot-bar':
      return {
        component: 'in-game-jackpot-bar',
        slug: attributes['game-slug']
      }
    default:
      return {
        component: attributes['component'] as Exclude<
          TApplicationParams['component'],
          | 'list'
          | 'search'
          | 'studios'
          | 'launcher'
          | 'likes-counter'
          | 'in-game-jackpot-bar'
          | 'casino-games'
        >
      }
  }
}
