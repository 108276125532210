import { fontWeight } from './weight'
// [fontSize, { lineHeight?, letterSpacing?, fontWeight? }].
export const fontSize = {
  /* Body */
  ['overline']: [
    '10px',
    {
      lineHeight: '10px',
      fontWeight: fontWeight.bold
    }
  ],
  ['2xs']: [
    '10px',
    {
      lineHeight: '12px',
      fontWeight: fontWeight.book
    }
  ],
  ['2xs-bold']: [
    '10px',
    {
      lineHeight: '12px',
      fontWeight: fontWeight.bold
    }
  ],
  ['xs']: [
    '12px',
    {
      lineHeight: '20px',
      fontWeight: fontWeight.book
    }
  ],
  ['xs-bold']: [
    '12px',
    {
      lineHeight: '20px',
      fontWeight: fontWeight.bold
    }
  ],
  ['sm']: [
    '14px',
    {
      lineHeight: '20px',
      fontWeight: fontWeight.book
    }
  ],
  ['sm-bold']: [
    '14px',
    {
      lineHeight: '20px',
      fontWeight: fontWeight.bold
    }
  ],
  ['base']: [
    '16px',
    {
      lineHeight: '24px',
      fontWeight: fontWeight.book
    }
  ],
  ['base-bold']: [
    '16px',
    {
      lineHeight: '24px',
      fontWeight: fontWeight.bold
    }
  ],
  ['lg']: [
    '20px',
    {
      lineHeight: '28px',
      fontWeight: fontWeight.book
    }
  ],
  ['lg-bold']: [
    '20px',
    {
      lineHeight: '28px',
      fontWeight: fontWeight.bold
    }
  ],

  /* Headings */
  ['h-sm']: [
    '16px',
    {
      lineHeight: '20px',
      fontWeight: fontWeight.bold
    }
  ],
  ['h']: [
    '20px',
    {
      lineHeight: '24px',
      letterSpacing: '-0.5px',
      fontWeight: fontWeight.bold
    }
  ],
  ['h-lg']: [
    '25px',
    {
      lineHeight: '28px',
      letterSpacing: '-0.5px',
      fontWeight: fontWeight.bold
    }
  ],
  ['h-xl']: [
    '31px',
    {
      lineHeight: '36px',
      letterSpacing: '-1px',
      fontWeight: fontWeight.bold
    }
  ],
  ['h-2xl']: [
    '39px',
    {
      lineHeight: '44px',
      letterSpacing: '-1px',
      fontWeight: fontWeight.bold
    }
  ],
  ['h-3xl']: [
    '48px',
    {
      lineHeight: '56px',
      letterSpacing: '-2px',
      fontWeight: fontWeight.bold
    }
  ],

  /* Buttons */
  ['btn-sm']: [
    '14px',
    {
      lineHeight: '20px',
      fontWeight: fontWeight.bold
    }
  ],
  ['btn']: [
    '16px',
    {
      lineHeight: '20px',
      fontWeight: fontWeight.bold
    }
  ],
  ['btn-lg']: [
    '20px',
    {
      lineHeight: '24px',
      fontWeight: fontWeight.bold
    }
  ]
}
