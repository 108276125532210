import { withHeaders } from './headers'
import { Fetch } from './types'

const AUTHORIZATION = 'authorization'
const X_PLAYER_MARKET = 'x-player-market'

/**
 * Creates a decorated version of the passed in fetch function that attaches an authorization header to the requests
 * using the provided *accessTokenProvider*.
 *
 * Requests that results in status code 401 will be retried with a refreshed token up to *maxRetries* times.
 *
 * @param fetch - The fetch function to decorate
 * @param accessTokenProvider - A function that provides the access token
 * @param marketCodeProvider - A function that provides the market code
 * @param [responseInterceptor] - An *optional* response interceptor that will be invoked before the response in returned to the caller
 * @param [maxRetries=1] - Max number of retries to attempt before giving up
 */
export const createAuthenticatedFetch = (
  fetch: Fetch,
  accessTokenProvider: () => Promise<string | undefined>,
  marketCodeProvider: () => Promise<string>,
  responseInterceptor?: (response: Response) => Promise<void>,
  maxRetries = 1
) => {
  return async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
    if (!(await accessTokenProvider())) {
      return withHeaders({ [X_PLAYER_MARKET]: await marketCodeProvider() }, fetch, input, init)
    }

    let retries = 0
    let response: Response
    do {
      response = await withHeaders(
        { [AUTHORIZATION]: `Bearer ${await accessTokenProvider()}` },
        fetch,
        input,
        init
      )
      if (response.status == 401) {
        await sleep(1000)
        continue
      }
      break
    } while (retries++ < maxRetries)
    if (responseInterceptor) {
      await responseInterceptor(response)
    }
    return response
  }
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
