export const isMobile = () => {
  if (!window || typeof window === 'undefined' || !window.navigator || !window.navigator.userAgent)
    return false

  return /\b(?:BlackBerry|webOS|iPhone|IEMobile|Android|Windows Phone|iPad|iPod)\b/i.test(
    window.navigator.userAgent
  )
}

/**
 * Wrap your usual dynamic import with this function.
 * It's compatible with React.lazy.
 *
 * We could implement a replacement for import function,
 * but because Parcel makes its own modifications for dynamic imports
 * when bundling (it replaces import() calls with its own require() with unique IDs of chunks)
 * it doesn't make sense to interfere with that and instead wrap it.
 */
export const decorateImportWithRetries =
  (importer: () => Promise<{ default: any }>) => async () => {
    try {
      return await importer()
    } catch (error: any) {
      // retry 5 times with 2 second delay and backoff factor of 2 (2, 4, 8, 16, 32 seconds).
      for (let i = 0; i < 5; i++) {
        await new Promise((resolve) => setTimeout(resolve, 1000 * 2 ** i))

        try {
          // Parcel JS fetches the chunk with timestamp appended, no need to manually add it here.
          return await importer()
        } catch (e) {
          console.warn('retrying import', e)
        }
      }

      // Re-throw original exception if retries failed.
      // We might want to catch it later and possibly reload the webapp
      // to have correct mapping of JS chunks.
      throw error
    }
  }
