import { ReactNode } from 'react'

type TProps = {
  title?: ReactNode
}

export function SearchTagsListSkeleton({ title }: TProps) {
  return (
    <div className="w-full overflow-hidden">
      {title}
      <div className="flex flex-nowrap gap-3xs before:pl-sm after:pr-sm 744:before:pl-2xl 744:after:pl-2xl">
        {[...Array(15).keys()].map((index) => (
          <div
            key={index}
            className="rounded-lg shrink-0 skeleton-grey inline-block w-[100px] h-lg"
          ></div>
        ))}
      </div>
    </div>
  )
}
