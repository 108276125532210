import cx from 'classnames'
import { TStyleVariantProps } from './types'
import { HTMLAttributes, forwardRef } from 'react'

export const VerticalStyleVariant = forwardRef<
  HTMLDivElement,
  TStyleVariantProps & HTMLAttributes<HTMLDivElement>
>(({ className, children, style }, ref) => (
  <div
    ref={ref}
    style={style}
    className={cx(
      '*:overflow-hidden',
      'grid gap-2xs px-sm @744/list:gap-sm @744/list:px-2xl',
      className
    )}
  >
    {children}
  </div>
))
