export const fontWeight = {
  thin: '100',
  extralight: '200',
  light: '300',
  book: '400',
  normal: '450',
  medium: '500',
  semibold: '600',
  bold: '700',
  extrabold: '800',
  black: '900'
}
