import cx from 'classnames'
import { type TStyleVariantProps } from './types'
import { HorizontalRowsStyleVariant } from './HorizontalRowsStyleVariant'

export const JackpotsStyleVariant = ({ className, children }: TStyleVariantProps) => (
  <HorizontalRowsStyleVariant
    className={cx(
      '[&:not(.game-row)]:*:aspect-[1/0.924] [&:not(.game-row)]:*:h-full',
      'aspect-[1/0.797] 744:aspect-[1/0.396] 1024:aspect-[1/0.411] 1440:aspect-[1/0.282] 1920:aspect-[1/0.215]',
      '*:w-[85.33%] 744:*:w-[42.58%] 1024:*:w-[44.53%] 1440:*:w-[30.35%] 1920:*:w-[23.13%]',
      className
    )}
  >
    {children}
  </HorizontalRowsStyleVariant>
)
