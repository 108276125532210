import cx from 'classnames'
import { TStyleVariantProps } from './types'

export const HorizontalStyleVariant = ({ className, children }: TStyleVariantProps) => (
  <div
    className={cx(
      'w-full *:h-full *:overflow-hidden',
      'flex flex-row flex-nowrap content-start *:grow-0 *:shrink-0',
      'gap-2xs before:pl-2xs after:pr-2xs @744/list:gap-sm @744/list:before:pl-lg @744/list:after:pr-lg',
      'snap-x *:snap-start *:scroll-mx-2xs @744/list:*:scroll-mx-lg',
      className
    )}
  >
    {children}
  </div>
)
