import cx from 'classnames'
import { type TStyleVariantProps } from './types'
import { HorizontalStyleVariant } from './HorizontalStyleVariant'

// 102px / 375px = 27.2%
// 98.66px / 768px = 13.262%
// 98px / 1024px = 13.77%
// 154px / 1440px = 10.694444444%
// 168px / 1920px = 8.75%

export const SmallCompactStyleVariant = ({ className, children }: TStyleVariantProps) => (
  <HorizontalStyleVariant
    className={cx(
      '*:aspect-[1/1]',
      '*:w-[27.2%] 744:*:w-[13.262%] 1024:*:w-[13.77%] 1440:*:w-[10.69%] 1920:*:w-[8.75%]',
      className
    )}
  >
    {children}
  </HorizontalStyleVariant>
)
