import { TLocaleCode } from '../gate/localisation'

export const LANGUAGE_LOCALE_SEPARATOR = '-'

export const isCurrentLocaleInFallback = (
  currentLocaleCode: TLocaleCode,
  fallbackLocaleCode?: TLocaleCode
) =>
  fallbackLocaleCode &&
  currentLocaleCode &&
  currentLocaleCode.split(LANGUAGE_LOCALE_SEPARATOR)[0] === fallbackLocaleCode

export const shouldCombineLocalesFn = (
  currentLocaleCode: TLocaleCode,
  fallbackLocaleCode?: TLocaleCode
) =>
  fallbackLocaleCode &&
  currentLocaleCode !== fallbackLocaleCode &&
  isCurrentLocaleInFallback(currentLocaleCode, fallbackLocaleCode)

export const createTranslationsParams = (
  currentLocaleCode: TLocaleCode,
  fallbackLocaleCode?: TLocaleCode
) => {
  const shouldCombineLocales = shouldCombineLocalesFn(currentLocaleCode, fallbackLocaleCode)

  if (shouldCombineLocales) {
    return {
      sort: '-languages_code',
      filter: {
        languages_code: {
          _in: [fallbackLocaleCode, currentLocaleCode]
        }
      }
    }
  } else {
    return {
      filter: {
        languages_code: {
          _eq: currentLocaleCode
        }
      }
    }
  }
}

export const pickTranslation = <T extends any[]>(translations: T): T[0] => translations[0] // We always pick the first translation in the array of translations because they are sorted from "more specific" (e.g "en-GB") to less specific (e.g "en")
