import { ReactNode, createContext, useContext } from 'react'

type TEventDispatcherContext = {
  dispatchEvent: (event: Event) => void
}

const EventDispatcherContext = createContext<TEventDispatcherContext>({
  dispatchEvent: () => null
})

type TProps = {
  children: ReactNode
  rootNode: Element
}

export const EventDispatcherProvider = ({ children, rootNode }: TProps) => {
  const context: TEventDispatcherContext = {
    dispatchEvent: (event: Event) => rootNode?.dispatchEvent(event)
  }

  return (
    <EventDispatcherContext.Provider value={context}>{children}</EventDispatcherContext.Provider>
  )
}

export const useEventDispatcher = () => {
  return useContext(EventDispatcherContext)
}
