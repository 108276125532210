import cx from 'classnames'

type TGameListTitleSkeleton = {
  skeletonClassName: string
}
export const GameListTitleSkeleton = ({ skeletonClassName }: TGameListTitleSkeleton) => {
  return (
    <div className="flex px-sm 744:px-2xl pb-sm 1024:pb-md gap-2xs">
      <div className={cx('rounded-full w-md h-md 1024:w-lg 1024:h-lg', skeletonClassName)}></div>
      <div
        className={cx(
          '744:pb-md text-sm rounded w-[176px] 1024:w-[240px] h-md 1024:h-lg',
          skeletonClassName
        )}
      ></div>
    </div>
  )
}
