export const spacing = {
  '3xs': '4px',
  '2xs': '8px',
  xs: '12px',
  sm: '16px',
  md: '24px',
  lg: '32px',
  xl: '40px',
  '2xl': '48px',
  '3xl': '56px'
}
