import type { ReactNode } from 'react'
import { gatekeeper } from 'Gatekeeper'

type TProps = {
  /** `link` in format `ROUTE_ID:param1=value1:param2=value2?query1=value1&query2=value2` or `https://example.com` */
  link?: string
  showIfRouteNotFound?: boolean
  children: ReactNode
  className?: string
  onClick?: () => void
  'data-testid'?: string
}

const UrlLink = ({ link, className, onClick, children, 'data-testid': dataTestId }: TProps) => {
  const clickHandler = onClick
    ? (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        onClick()
      }
    : undefined

  return (
    <a href={link} className={className} onClick={clickHandler} data-testid={dataTestId}>
      {children}
    </a>
  )
}

const RouteIdLink = ({
  link = '',
  showIfRouteNotFound,
  className,
  onClick,
  children,
  'data-testid': dataTestId
}: TProps) => {
  const linkParts = link.split('?')
  const routeParts = linkParts[0].split(':')
  const routeId = routeParts[0]
  const routeParams = routeParts
    ? Object.fromEntries(routeParts.slice(1).map((param) => param.split('=')))
    : undefined
  const queryParams = new URLSearchParams(linkParts[1])
  const url = gatekeeper.navigation.getRoute(routeId, routeParams)

  if (!url || url.includes('undefined')) {
    return showIfRouteNotFound ? (
      <div onClick={onClick} className={className} data-testid={dataTestId}>
        {children}
      </div>
    ) : null
  }

  const hasAnyQueryParams = [...queryParams].length > 0
  const urlWithParams = hasAnyQueryParams ? `${url}?${queryParams.toString()}` : url
  const clickHandler = onClick
    ? (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        onClick()
      }
    : (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        gatekeeper.navigation.navigate(routeId, {
          params: routeParams,
          query: hasAnyQueryParams ? queryParams.toString() : undefined
        })
      }

  return (
    <a href={urlWithParams} onClick={clickHandler} className={className} data-testid={dataTestId}>
      {children}
    </a>
  )
}

export const Link = (props: TProps) => {
  if (!props?.link?.length && !props.showIfRouteNotFound) {
    return null
  } else if (props.link?.startsWith('http')) {
    return <UrlLink {...props} />
  } else {
    return <RouteIdLink {...props} />
  }
}
