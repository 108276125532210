import cx from 'classnames'
import { type TStyleVariantProps } from './types'
import { HorizontalStyleVariant } from './HorizontalStyleVariant'

// 321px / 375px = 85.6%
// 327px / 768px = 42.578125%
// 300px / 1024px = 29.296875%
// 324px / 1440px = 22.5%
// 352px / 1920px = 18.333333333%

export const DoubleWidthStyleVariant = ({ className, children }: TStyleVariantProps) => (
  <HorizontalStyleVariant
    className={cx(
      '*:aspect-[1/0.924]',
      '*:w-[85.6%] 744:*:w-[42.58%] 1024:*:w-[29.3%] 1440:*:w-[22.5%] 1920:*:w-[18.33%]',
      className
    )}
  >
    {children}
  </HorizontalStyleVariant>
)
