import { ReactNode } from 'react'
import { LegacyGameListSkeleton } from './LegacyGameListSkeleton'
import type { TGameListBackground, TGameListDescription, TGameListType } from '../types'
import { GameListSkeleton } from './GameListSkeleton'

type SkeletonListProps = {
  variant: TGameListType
  title?: ReactNode
} & Partial<TGameListDescription> &
  Partial<TGameListBackground>

type TGameListSkeletonComponent = (props: SkeletonListProps) => JSX.Element
export const LIST_COMPONENT_SKELETON_MAPPING: {
  [key in TGameListType]: TGameListSkeletonComponent
} = {
  'legacy-default': LegacyGameListSkeleton,
  'legacy-large': LegacyGameListSkeleton,
  'legacy-double-width': LegacyGameListSkeleton,
  default: GameListSkeleton,
  'small-default': GameListSkeleton,
  compact: GameListSkeleton,
  'small-compact': GameListSkeleton,
  large: GameListSkeleton,
  'double-width': GameListSkeleton,
  jackpots: GameListSkeleton,
  'compact-grid': GameListSkeleton,
  'default-grid': GameListSkeleton,
  'small-default-grid': GameListSkeleton,
  'small-compact-grid': GameListSkeleton
}

export const SkeletonList = (props: SkeletonListProps) => {
  const SkeletonListComponent = LIST_COMPONENT_SKELETON_MAPPING[props.variant]

  return <SkeletonListComponent {...props} />
}
