import cx from 'classnames'
import { TStyleVariantProps } from './types'

export const LegacyHorizontalStyleVariant = ({ className, children }: TStyleVariantProps) => (
  <div
    className={cx(
      'flex flex-row flex-nowrap *:grow-0 *:shrink-0',
      'gap-2xs before:pl-2xs after:pr-2xs',
      'legacy-tablet:before:pl-xl legacy-tablet:after:pr-xl',
      'legacy-desktop:before:hidden legacy-desktop:after:hidden',
      className
    )}
  >
    {children}
  </div>
)
